import React from "react"
import Img from "gatsby-image"
import emailjs from "emailjs-com"
import FormInput from "./formInput"
import BodyText from "../components/bodyText"
import "../styles/components/_contactForm.scss"

export default class ContactForm extends React.Component {
  submitForm = e => {
    e.preventDefault()
    const parentElem = e.currentTarget.parentElement.parentElement

    const templateParams = {
      partnerName: parentElem.querySelector('input[name="name"]').value,
      partnerEmail: parentElem.querySelector('input[name="email-address"]')
        .value,
      partnerWebsite: parentElem.querySelector('input[name="company-website"]')
        .value,
      partnerMessage: parentElem.querySelector('textarea[name="message"]')
        .value,
    }

    emailjs
      .send(
        "mailgun",
        "talent_inc_site",
        templateParams,
        process.env.GATSBY_EMAILJS_USER_ID
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text)
          parentElem.classList.add("submitted")
          parentElem.reset()
        },
        err => {
          console.log("FAILED...", err)
        }
      )
  }
  render() {
    const contactData = this.props
    return (
      <div className="contact-form">
        <form>
          <h2>{contactData.title}</h2>
          {contactData.labels.map((label, n) => (
            <FormInput label={label} key={n} num={n} />
          ))}
          <input type="text" name="_gotcha" className="gotcha" />

          <div className="bottom">
            <BodyText text={contactData.footnote} />
            <div className="contact-button" onClick={this.submitForm}>
              {contactData.buttonText}
              <img
                src={contactData.buttonIcon.file.url}
                alt={contactData.buttonIcon.description}
              />
            </div>
            <BodyText text={contactData.thankYou} />
          </div>
        </form>
        <Img
          fluid={contactData.shadow.fluid}
          alt={contactData.shadow.description}
          className="form-shadow"
        />
      </div>
    )
  }
}
