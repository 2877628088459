import React from "react"
import "../styles/components/_formInput.scss"

const FormInput = ({ label, num }) => {
  const labelClass = label
    .replace("*", "")
    .replace(" ", "-")
    .toLowerCase()

  const inputType = labelClass =>
    ({
      "email-address": "email",
      "company-website": "text",
      name: "text",
    }[labelClass])

  const requiredField = label.includes("*") ? true : false

  if (labelClass === "message") {
    return (
      <label className={`form-input ${labelClass}`} key={num}>
        {label}
        <textarea name={labelClass} />
      </label>
    )
  } else {
    return (
      <label className={`form-input ${labelClass}`} key={num}>
        {label}
        <input
          type={inputType(labelClass)}
          name={labelClass}
          required={requiredField}
        />
      </label>
    )
  }
}

export default FormInput
