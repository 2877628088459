import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import handleScroll from "../functions/handleScroll"
import Header from "../components/header"
import Meta from "../components/meta"
import TitleHeader from "../components/titleHeader"
import BodyText from "../components/bodyText"
import ContactForm from "../components/contactForm"
import Statistic from "../components/statistic"
import Footer from "../components/footer"
import "../styles/pages/_partnerships.scss"

export default class PartnershipsPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll)
  }

  render() {
    const partnershipsData = this.props.data.allContentfulPartnershipsPage
      .nodes[0]

    return (
      <div className="partnerships">
        <Meta
          title={partnershipsData.seo.title}
          description={partnershipsData.seo.description.description}
          image={partnershipsData.seo.socialImage.file.url}
          url={this.props.location.href}
          imgAlt={partnershipsData.seo.socialImage.description}
        />
        <Header />
        <TitleHeader title={partnershipsData.title} />
        <div className="content">
          <div className="overview">
            <BodyText text={partnershipsData.bodyText.json} />
            <Img
              fluid={partnershipsData.mainImage.fluid}
              alt={partnershipsData.mainImage.description}
              className="main-image"
            />
          </div>
          <div className="contact">
            <ContactForm
              title={partnershipsData.formTitle}
              labels={partnershipsData.formLabels}
              footnote={partnershipsData.formFootnote.json}
              buttonText={partnershipsData.formButtonText}
              buttonIcon={partnershipsData.formButtonIcon}
              shadow={partnershipsData.formShadow}
              thankYou={partnershipsData.thankYouMessage.json}
            />
            <ul className="statistics">
              {partnershipsData.statistics.map((statistic, n) => (
                <li key={n}>
                  <Statistic
                    icon={statistic.icon}
                    statistic={statistic.statistic}
                    description={statistic.description}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query partnershipsPageQuery {
    allContentfulPartnershipsPage {
      nodes {
        title
        bodyText {
          json
        }
        mainImage {
          fluid {
            ...GatsbyContentfulFluid
          }
          file {
            url
          }
        }
        formTitle
        formLabels
        formButtonText
        formButtonIcon {
          description
          file {
            url
          }
        }
        formFootnote {
          json
        }
        formShadow {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        statistics {
          icon {
            description
            file {
              url
            }
          }
          statistic
          description
        }
        thankYouMessage {
          json
        }
        seo {
          title
          description {
            description
          }
          socialImage {
            description
            file {
              url
            }
          }
        }
      }
    }
  }
`
