import React from "react"
import "../styles/components/_statistic.scss"

const Statistic = ({ icon, statistic, description }) => {
  return (
    <div className="statistic">
      <img src={icon.file.url} alt={icon.description} />
      <div className="statistic-num">{statistic}</div>
      <h3>{description}</h3>
    </div>
  )
}

export default Statistic
